import { NAMESPACE, ActionTypes, MutationTypes } from '@pixcap/ui-core/models/store/survey.interface';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { HttpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';

const { ASSETMANAGER_PATH } = API_PATHS;

export default {
	async [ActionTypes.GET_SURVEY_DATA]({ commit }) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_LOADING_SURVEY}`, true, { root: true });
		try {
			const response = await (this.$httpClient as HttpClient).get(`${ASSETMANAGER_PATH}/survey`);
			if (response.data) commit(`${NAMESPACE}/${MutationTypes.SET_QUESTIONS_LIST}`, response.data, { root: true });
		} finally {
			commit(`${NAMESPACE}/${MutationTypes.SET_IS_LOADING_SURVEY}`, false, { root: true });
		}
	},
	async [ActionTypes.SUBMIT_SURVEY_ANSWERS]({ commit }, answers: { questionId: string; answer: string | string[] }[]) {
		try {
			await (this.$httpClient as HttpClient).post(`${ASSETMANAGER_PATH}/survey`, { answers });
		} catch {}
	},
};
