import { ISurvey } from '@pixcap/ui-core/models/store/survey.interface';

const getDefaultState = function (): ISurvey {
	return {
		questions: [],
		isLoadingSurvey: false,
		showOnboardingModal: false,
	};
};

export default (): ISurvey => getDefaultState();
