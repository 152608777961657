import { MutationTypes } from '@pixcap/ui-core/models/store/survey.interface';

export default {
	[MutationTypes.SET_IS_LOADING_SURVEY]: (state, isLoadingSurvey) => {
		state.isLoadingSurvey = isLoadingSurvey;
	},
	[MutationTypes.SET_SHOW_ONBOARDING_MODAL](state, showOnboardingModal) {
		state.showOnboardingModal = showOnboardingModal;
	},
	[MutationTypes.SET_QUESTIONS_LIST]: (state, questions) => {
		state.questions = questions;
	},
};
