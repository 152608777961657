import { INIT_EMAIL_NOTIFICATIONS } from '@pixcap/ui-core/constants/user.constants';
import { MY_ACCOUNT_TAB, UserState } from '@pixcap/ui-core/models/store/user.interface';
import { SortBy } from '@pixcap/ui-core/models/store/app.interface';

const getDefaultState = function (): UserState {
	return {
		personalData: undefined,
		userId: null,
		userCountry: null,
		subscriptionInfo: {
			subscription: null,
			paymentStatus: null,
			currentPeriodEnd: '',
			isFreeTrialInitiated: false,
			pricingCountryCode: null,
		},
		referralsInfo: {
			referralCode: null,
			rewards: [],
		},
		notifications: {
			page: 0,
			totalItems: 0,
			totalPages: null,
			content: [],
			size: 10,
		},
		emailNotifications: INIT_EMAIL_NOTIFICATIONS,
		showCancelPlanModal: false,
		showAccountSettingModal: false,
		showReferralModal: false,
		showReferralPopoverModal: false,
		showNoAuthModal: false,
		activeMyAccountTab: MY_ACCOUNT_TAB.ACCOUNT_SETTINGS,
		CroppPictureModal: null,
		isLoadingUserInfo: false,
		upgradeModalState: null,
		topUpLimitsModalState: null,
		toppedCreditsQuantityForPayment: null,
		showPortalPaymentStatus: null,
		selectedSubscriptionForPayment: null,
		showResetPasswordModal: null,
		showContactModal: null,
		isSendingMessage: false,
		showAttributionDownloadModal: null,
		cartItems: [],
		cartItemsId: [],
		isFetchingCartItems: false,
		showCartPaymentMethodModal: null,
		showCartPaymentStatusPopoverModal: null,

		isFetchingPurchaseItems: false,
		purchaseItemFilter: {
			sortBy: SortBy.RECENT,
			currentPage: 0,
		},
		purchaseItems: [],
		purchaseCurrentPage: 0,
		purchaseTotalPages: null,
	};
};

export default (): UserState => getDefaultState();
