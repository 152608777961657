import { NAMESPACE, ActionTypes, MutationTypes } from '@pixcap/ui-core/models/store/survey.interface';
import type { Dispatch, Commit } from 'vuex';

export const actionsWrapper = {
	async getSurveyData({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_SURVEY_DATA}`, null, { root: true });
	},
	async submitSurveyAnswers({ dispatch }: { dispatch: Dispatch }, answers: { questionId: string; answer: string | string[] }[]) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBMIT_SURVEY_ANSWERS}`, answers, { root: true });
	},
};

export const mutationsWrapper = {
	setIsLoadingSurvey({ commit }: { commit: Commit }, isLoadingSurvey: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_LOADING_SURVEY}`, isLoadingSurvey, { root: true });
	},
	setShowOnboardingModal({ commit }: { commit: Commit }, showOnboardingModal: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_ONBOARDING_MODAL}`, showOnboardingModal, { root: true });
	},
	setQuestionsList({ commit }: { commit: Commit }, questions) {
		commit(`${NAMESPACE}/${MutationTypes.SET_QUESTIONS_LIST}`, questions, { root: true });
	},
};
